import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject, takeUntil, timer } from "rxjs";

@Component({
  selector: "app-spinner-and-text",
  templateUrl: "./spinner-and-text.component.html",
  styleUrls: ["./spinner-and-text.component.scss"],
})
export class SpinnerAndTextComponent implements OnInit, OnDestroy {
  @Input() text = "Loading";

  @Input() classes = "w-6 h-6 text-gray-200 dark:text-gray-600 fill-blue-600";
  @Input() textClasses = "text-h2";

  currentText = this.text || "";

  private numDots = 0;

  private destroy$ = new Subject<void>();

  private TICK_INTERVAL_MS = 500;

  ngOnInit() {
    timer(this.TICK_INTERVAL_MS, this.TICK_INTERVAL_MS)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (tick) => {
          this.numDots = (this.numDots + 1) % 4;
          this.currentText = this.text + ".".repeat(this.numDots);
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
