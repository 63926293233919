import { app } from "@shared/config/app.config";
import { regex } from "./regex.config";
import { countryCodes } from "./country-code.config";
import { profileDiversity, signUpDiversity } from "./diversity.config";
import { tabs } from "./tabs.config";
import { tokenUrls } from "./token-urls.config";
import { profile } from "./profile.config";
import { pagination } from "@shared/config/pagination";
import { sidebar } from "./sidebar.config";
import { user } from "./user.config";
import { message } from "./message.config";
import { websocket } from "@shared/config/websocket.config";
import { backendRoutes } from "@shared/config/backend-routes";
import { newsTypeToRoute } from "./news.config";
import { frontendRoutes } from "./frontend-routes.config";
import { genderOptions } from "@shared/config/gender-options.config";
import { dateFormatsConfig } from "@shared/config/date-formats.config";
import { comments } from "@shared/config/comments.config";
import { footer } from "@shared/config/footer.config";
import { mentorshipTabs } from "@shared/config/mentorship-tabs.config";
import { platforms } from "@shared/config/platforms.config";
import { platformThemes } from "@shared/config/platform-themes.config";
import { groupTag } from "@shared/config/group-tag";

export * from "./app.config";
export * from "./regex.config";
export * from "./country-code.config";
export * from "./diversity.config";
export * from "./tabs.config";
export * from "./token-urls.config";
export * from "./profile.config";
export * from "./sidebar.config";
export * from "./user.config";
export * from "./news.config";
export * from "./frontend-routes.config";
export * from "./gender-options.config";
export * from "./date-formats.config";
export * from "./comments.config";
export * from "./footer.config";
export * from "./platforms.config";
export * from "./group-tag";

export const config = {
  app,
  backendRoutes,
  regex,
  countryCodes,
  signUpDiversity,
  profileDiversity,
  tabs,
  mentorshipTabs,
  tokenUrls,
  profile,
  pagination,
  sidebar,
  user,
  message,
  websocket,
  newsTypeToRoute,
  frontendRoutes,
  genderOptions,
  dateFormatsConfig,
  comments,
  footer,
  platforms,
  platformThemes,
  groupTag,
};
