import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { isNil } from "lodash";

@Component({
  selector: "app-survey-results",
  templateUrl: "./survey-results.component.html",
  styleUrls: ["./survey-results.component.scss"],
})
export class SurveyResultsComponent implements OnInit {
  @Input() resultsUrl: string;

  _surveyDetails: any;
  get surveyDetails(): any {
    return this._surveyDetails;
  }
  @Input() set surveyDetails(value: any) {
    this._surveyDetails = value;
    this.question = value?.elements[0]?.title;
  }

  // TODO this will need to be changed when we add different question types
  question: string;
  loading: boolean = false;
  showIframe: boolean = false;
  iframeUrl: SafeResourceUrl;
  constructor(private sanitizer: DomSanitizer) {}

  get embedUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.resultsUrl!);
  }

  iFrameLoaded() {
    this.loading = false;
  }
  showSpinner() {
    this.loading = true;
  }

  ngOnInit() {
    if (!isNil(this.resultsUrl)) {
      this.showIframe = true;
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.resultsUrl!,
      );
    }
  }
}
