<app-spinner-and-text *ngIf="loading"></app-spinner-and-text>

<div *ngIf="!loading">
  <span class="survey-header text-xs"
    >Thank you for participating in the survey!</span
  >

  <br />
  <br />

  <p style="font-size: 1.25rem">{{ question }}</p>

  <iframe
    (load)="iFrameLoaded()"
    (loadstart)="showSpinner()"
    [src]="iframeUrl"
    class="iframe-content"
    height="600"
    frameborder="0"
  ></iframe>
</div>
