import { loadingState, LoadingState } from "@shared/helpers/loading-state";
import { UserSkills } from "@shared/models/user-skills.model";
import { CompanyRoles } from "@shared/models/company-roles.model";
import { Expertise } from "@shared/models/expertise.model";
import { Interest } from "@shared/models/interest.model";
import { SectorExperience } from "@shared/models/sector-experience.model";
import { Location } from "@shared/models/location.model";
import { ValueAddedServices } from "@models";

export interface SelectOptionsState {
  getSectorExperience: LoadingState;
  getLocation: LoadingState;
  getInterests: LoadingState;
  getExpertise: LoadingState;
  getSignupSelectOptions: LoadingState;
  getUserSkills: LoadingState;
  getCompanyRoles: LoadingState;
  locations: Location[];
  userSkills: UserSkills;
  companyRoles: CompanyRoles;
  platformExpertise: Expertise[];
  platformInterests: Interest[];
  platformSectorExperiences: SectorExperience[];
  getValueAddedServices: LoadingState;
  valueAddedServices: ValueAddedServices[];
}

export const initialSelectOptionsState: SelectOptionsState = {
  getSectorExperience: loadingState(),
  getExpertise: loadingState(),
  getInterests: loadingState(),
  getLocation: loadingState(),
  getSignupSelectOptions: loadingState(),
  getUserSkills: loadingState(),
  getCompanyRoles: loadingState(),
  locations: [],
  userSkills: {},
  companyRoles: {
    role: [],
    company: [],
    status: {
      checked: false,
      value: "",
      displayName: "",
      optionName: "",
    },
    primary: false,
  },
  platformExpertise: [],
  platformInterests: [],
  platformSectorExperiences: [],
  getValueAddedServices: loadingState(),
  valueAddedServices: [],
};
