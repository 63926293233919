import { GeneralEntityState } from "@shared/store/models/general-entity-state.model";
import { Survey } from "@models";
import { ApiStateHelper } from "@shared/helpers/api-state.helper";
import { Action, createReducer } from "@ngrx/store";
import { defaultPaginationState } from "@shared/store/models/pagination-entity-state.model";

export interface SurveyState extends GeneralEntityState<Survey> {
  // Insert custom state here
}

// A helper class that extends the API State Helper with the right information
class SurveyStateHelper extends ApiStateHelper<Survey, {}, any, SurveyState> {
  constructor() {
    super("Survey", "survey", "surveyId");
  }
}

export const SurveyNgrx = new SurveyStateHelper();

export const initialState: SurveyState = SurveyNgrx.getInitialState({
  pagination: {
    ...defaultPaginationState,
    pageSize: 20,
    noPaginate: true,
  },
});

export const surveyReducer = createReducer(
  initialState,
  ...SurveyNgrx.ons,
  ...SurveyNgrx.apiOns,
);

export function reducer(
  state: SurveyState = initialState,
  action: Action,
): SurveyState {
  return surveyReducer(state, action);
}
