import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import { PublicSurveyPageComponent } from "./pages/public-survey-page/public-survey-page.component";
import { PublicSurveyRoutingModule } from "./public-survey-routing.module";
import { StoreModule } from "@ngrx/store";
import * as fromUser from "@survey/store/survey.ngrx";
import { SurveyNgrx } from "@survey/store/survey.ngrx";
import { EffectsModule } from "@ngrx/effects";
import { SurveyEffects } from "@survey/store/survey.effects";
import { SurveyPublicComponent } from "./components/survey-public/survey-public.component";
import { NgxCaptchaModule } from "ngx-captcha";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PublicSurveyRoutingModule,
    StoreModule.forFeature(SurveyNgrx.featureStateKey, fromUser.reducer),
    EffectsModule.forFeature([SurveyEffects]),
    NgxCaptchaModule,
  ],
  declarations: [PublicSurveyPageComponent, SurveyPublicComponent],
})
export class PublicSurveyModule {}
