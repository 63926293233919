import { Component, Input, OnInit } from "@angular/core";
import { Model, SurveyModel } from "survey-core";
import {
  Team350Store,
  TrackingHelpers,
} from "@shared/store/action-trackers/services/team-350.store";
import { AuthUser, Survey } from "@models";
import { LandingSurveyResponse } from "@survey/models/landing-survey-response.model";
import { submitResponseForPublicSurvey } from "@survey/store/survey.actions";
import lightThemeJson from "@shared/survey/themes/survey-light-theme.json";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { regex } from "@config";
import { PublicSurveyResponseModel } from "@survey/models/public-survey-response.model";
import { Router } from "@angular/router";
import { isNil } from "lodash";
import { selectAuthUser } from "@auth/store/auth.selectors";
import { filter, map } from "rxjs";

@Component({
  selector: "app-survey-public",
  templateUrl: "./survey-public.component.html",
  styleUrls: ["./survey-public.component.scss"],
})
export class SurveyPublicComponent implements OnInit {
  model: SurveyModel;
  survey: Survey;
  @Input() surveyResponse: LandingSurveyResponse;
  @Input() platform: string;
  surveySubmitted: boolean;
  submitSurveyResponseTracker: TrackingHelpers;
  formValid: boolean = false;
  userIdSelector$ = this.store.select(selectAuthUser);
  userLoggedIn: boolean = false;
  user: AuthUser;

  surveyEmailForm: FormGroup;

  constructor(
    private store: Team350Store,
    private router: Router,
  ) {
    this.surveyEmailForm = new FormGroup({
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(regex.email),
      ]),
    });
  }

  ngOnInit() {
    this.userIdSelector$
      .pipe(
        filter((user) => !isNil(user)),
        map((user): AuthUser => user as AuthUser),
      )
      .subscribe((user) => {
        if (!isNil(user)) {
          this.user = user;
          this.userLoggedIn = true;
        }
      });
    this.surveyEmailForm.valueChanges.subscribe(() => {
      if (!isNil(this.model.data) && this.surveyEmailForm.valid) {
        this.formValid = true;
      }
    });
    this.survey = this.surveyResponse.survey;

    this.model = new Model(this.survey.details);
    this.model.applyTheme(lightThemeJson);
    this.model.showCompleteButton = false;
    this.model.onValueChanged.add(() => {
      if (
        (!isNil(this.model.data) && this.surveyEmailForm.valid) ||
        this.userLoggedIn
      ) {
        this.formValid = true;
      }
    });
  }

  submitResponse() {
    const formValues = this.surveyEmailForm.value;
    const email = this.userLoggedIn ? this.user.email : formValues.email;
    const surveyResponse: PublicSurveyResponseModel = {
      surveyId: this.survey.surveyId,
      surveyResponse: this.model.data,
      platform: this.platform,
      email: email,
    };

    this.submitSurveyResponseTracker = this.store.trackDispatch(
      submitResponseForPublicSurvey({ surveyResponse }),
    );

    this.submitSurveyResponseTracker.onComplete().subscribe((result) => {
      this.surveySubmitted = true;
    });
  }

  navigateToHomePage() {
    this.router.navigate(["/landing"]);
  }
}
