import { config } from "@shared/config";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class PlatformDetailsService {
  protected readonly config = config;

  setPlatformDetails(platformName: string) {
    switch (platformName) {
      case config.platforms.team350Club:
        return {
          platformTheme: config.platformThemes.team350Club,
          platformLogoFileName: `/assets/images/platform-logos/350-club-infinity-light.svg`,
        };
      case config.platforms.boardhouse:
        return {
          platformTheme: config.platformThemes.boardhouse,
          platformLogoFileName: `/assets/images/platform-logos/board-house-logo-dark.svg`,
        };
      default:
        return {
          platformTheme: config.platformThemes.team350Club,
          platformLogoFileName: `/assets/images/platform-logos/350-club-infinity-light.svg`,
        };
    }
  }
}
