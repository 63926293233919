import { Component, Input } from "@angular/core";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent {
  @Input() classes = "w-6 h-6 text-gray-200 dark:text-gray-600 fill-blue-600";
}
