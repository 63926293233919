<div
  [ngClass]="platformTheme ? platformTheme : config.platformThemes.team350Club"
  class="about-page"
>
  <div class="content-container">
    <div class="header">
      <div class="logo-header">
        <img
          (click)="landingRedirect()"
          [src]="platformLogoFileName"
          alt="logo"
          class="logo"
        />
      </div>
    </div>
    <div class="page-content">
      <app-survey-public
        *ngIf="surveyResponse"
        [platform]="platformName"
        [surveyResponse]="surveyResponse"
      ></app-survey-public>
    </div>
  </div>
</div>
