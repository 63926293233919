import { createSelector } from "@ngrx/store";
import { SelectOptionsState } from "./select-options.state";
import { selectSelectOptionsState } from "./select-options.feature";

export const selectGetLocations = createSelector(
  selectSelectOptionsState,
  (state: SelectOptionsState) => state.locations,
);
export const selectGetUserSkills = createSelector(
  selectSelectOptionsState,
  (state: SelectOptionsState) => state.userSkills,
);

export const selectCompanyRoles = createSelector(
  selectSelectOptionsState,
  (state: SelectOptionsState) => state.companyRoles,
);

export const selectPlatformExpertise = createSelector(
  selectSelectOptionsState,
  (state: SelectOptionsState) => state.platformExpertise,
);

export const selectPlatformInterests = createSelector(
  selectSelectOptionsState,
  (state: SelectOptionsState) => state.platformInterests,
);
export const selectPlatformSectorExperiences = createSelector(
  selectSelectOptionsState,
  (state: SelectOptionsState) => state.platformSectorExperiences,
);

export const selectValueAddedServices = createSelector(
  selectSelectOptionsState,
  (state: SelectOptionsState) => state.valueAddedServices,
);
